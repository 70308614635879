import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  updateUrlParam(paramName, paramValue) {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set(paramName, paramValue);
    history.pushState({}, '', currentUrl);
  }

  updateTab(event) {
    const url = new URL(this.element.href);
    const tab = url.searchParams.get("tab");

    this.updateUrlParam("tab", tab);
  }

  updateTaxiId(event) {
    const url = new URL(this.element.href);
    const taxiId = url.searchParams.get("taxi_id");

    this.updateUrlParam("taxi_id", taxiId);
  }
}
